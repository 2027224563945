.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /* padding-left: 10px;
  padding-right: 10px; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font: 14px/16px Arial, Helvetica, sans-serif;
}
/* .dropzone {
  padding-left: 10px;
  padding-right: 10px;
}
 */

.title {
  color:#F9423A;
  /* text-align: center; */
  /* padding: 10px 0; */
  position: relative;
  line-height: 1.2;
  margin-left: 50px;
  word-break: break-word;
}

.description {
  line-height: 1.2;
  font-size: 15px;
  word-break: break-all;
}

.comment-matrix {
  padding-top: 10px;
}

.export-button {
  padding-bottom: 10px;
}

#footer {
  color: grey;
  /* text-align: center; */
  position: absolute;
  bottom: 0;
  text-wrap: wrap;
  float: left;
  word-break: normal;
  width: 20%;
}

.button-group {
  margin-bottom: 10px;
  margin-top: 10px;
}

.button-red {
  background-color: #F9423A;
  border-color: none;
  margin-right: 10px;
}

.button-blue {
  background-color: #001E60;
  border-color: none;
}

.sidebar-area {
  width: 20%;
  float: left;
  background-color: rgba(217, 217, 214, 0.5);
  height: 100vh;
  padding: 10px;
}

.cpcs-icon {
  /* float: left; */
  margin-right: 10px;
  float: left;
}
/* .sidebar-title {
  position:inherit;
} */
.cpcs-icon > img {
  height: 50px;
}

.table-area {
  top: 0;
  margin-left: 20%;
  padding-top: 5%;
  padding-left: 10px;
  padding-right: 10px;
}

.file-info {
  word-break: break-all;
  font-style: italic;
  color:rgb(100, 112, 112);
}

.cpcslabs-logo {
  position: absolute;
  width: 18%;
  bottom: 50px;
}

.cpcslabs-logo > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
